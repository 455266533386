import React from "react";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Suite Venture Housing</title>
        <meta charSet="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="author" content="" />
        <meta name="keywords" content="" />
        <meta name="description" content="" />
        <link rel="stylesheet" type="text/css" href="/css/bootstrap.min.css" />
        <link rel="stylesheet" type="text/css" href="/style.css" />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700&family=Poppins:wght@200;300;400;500&display=swap"
          rel="stylesheet"
        />
        <script src="/js/modernizr.js"></script>
      </Helmet>
      <body
        className="bg-body"
        data-bs-spy="scroll"
        data-bs-target="#navbar"
        data-bs-root-margin="0px 0px -40%"
        data-bs-smooth-scroll="true"
        tabindex="0"
      >
        <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
          <symbol
            id="search"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <title>Search</title>
            <path
              fill="currentColor"
              d="M19 3C13.488 3 9 7.488 9 13c0 2.395.84 4.59 2.25 6.313L3.281 27.28l1.439 1.44l7.968-7.969A9.922 9.922 0 0 0 19 23c5.512 0 10-4.488 10-10S24.512 3 19 3zm0 2c4.43 0 8 3.57 8 8s-3.57 8-8 8s-8-3.57-8-8s3.57-8 8-8z"
            />
          </symbol>
          <symbol
            xmlns="http://www.w3.org/2000/svg"
            id="user"
            viewBox="0 0 16 16"
          >
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
          </symbol>
          <symbol
            xmlns="http://www.w3.org/2000/svg"
            id="cart"
            viewBox="0 0 16 16"
          >
            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </symbol>
          <symbol
            xmlns="http://www.w3.org/2000/svg"
            id="arrow-left"
            viewBox="0 0 32 32"
          >
            <path
              fill="currentColor"
              d="m13.281 6.781l-8.5 8.5l-.687.719l.687.719l8.5 8.5l1.438-1.438L7.938 17H28v-2H7.937l6.782-6.781z"
            />
          </symbol>
          <symbol
            xmlns="http://www.w3.org/2000/svg"
            id="arrow-right"
            viewBox="0 0 32 32"
          >
            <path
              fill="currentColor"
              d="M18.719 6.781L17.28 8.22L24.063 15H4v2h20.063l-6.782 6.781l1.438 1.438l8.5-8.5l.687-.719l-.687-.719z"
            />
          </symbol>
          <symbol
            xmlns="http://www.w3.org/2000/svg"
            id="shipping-fast"
            viewBox="0 0 32 32"
          >
            <path
              fill="currentColor"
              d="M0 6v2h19v15h-6.156c-.446-1.719-1.992-3-3.844-3c-1.852 0-3.398 1.281-3.844 3H4v-5H2v7h3.156c.446 1.719 1.992 3 3.844 3c1.852 0 3.398-1.281 3.844-3h8.312c.446 1.719 1.992 3 3.844 3c1.852 0 3.398-1.281 3.844-3H32v-8.156l-.063-.157l-2-6L29.72 10H21V6zm1 4v2h9v-2zm20 2h7.281L30 17.125V23h-1.156c-.446-1.719-1.992-3-3.844-3c-1.852 0-3.398 1.281-3.844 3H21zM2 14v2h6v-2zm7 8c1.117 0 2 .883 2 2s-.883 2-2 2s-2-.883-2-2s.883-2 2-2zm16 0c1.117 0 2 .883 2 2s-.883 2-2 2s-2-.883-2-2s.883-2 2-2z"
            />
          </symbol>
          <symbol
            xmlns="http://www.w3.org/2000/svg"
            id="shopping-cart"
            viewBox="0 0 32 32"
          >
            <path
              fill="currentColor"
              d="M5 7c-.55 0-1 .45-1 1s.45 1 1 1h2.219l2.625 10.5c.222.89 1.02 1.5 1.937 1.5H23.25c.902 0 1.668-.598 1.906-1.469L27.75 10H11l.5 2h13.656l-1.906 7H11.781L9.156 8.5A1.983 1.983 0 0 0 7.22 7zm17 14c-1.645 0-3 1.355-3 3s1.355 3 3 3s3-1.355 3-3s-1.355-3-3-3zm-9 0c-1.645 0-3 1.355-3 3s1.355 3 3 3s3-1.355 3-3s-1.355-3-3-3zm0 2c.563 0 1 .438 1 1c0 .563-.438 1-1 1c-.563 0-1-.438-1-1c0-.563.438-1 1-1zm9 0c.563 0 1 .438 1 1c0 .563-.438 1-1 1c-.563 0-1-.438-1-1c0-.563.438-1 1-1z"
            />
          </symbol>
          <symbol
            xmlns="http://www.w3.org/2000/svg"
            id="gift"
            viewBox="0 0 32 32"
          >
            <path
              fill="currentColor"
              d="M12 5c-1.645 0-3 1.355-3 3c0 .352.074.684.188 1H4v6h1v13h22V15h1V9h-5.188A2.95 2.95 0 0 0 23 8c0-1.645-1.355-3-3-3c-1.75 0-2.938 1.328-3.719 2.438c-.105.148-.187.292-.281.437c-.094-.145-.176-.29-.281-.438C14.938 6.329 13.75 5 12 5zm0 2c.625 0 1.438.672 2.063 1.563c.152.218.128.23.25.437H12c-.566 0-1-.434-1-1c0-.566.434-1 1-1zm8 0c.566 0 1 .434 1 1c0 .566-.434 1-1 1h-2.313c.122-.207.098-.219.25-.438C18.563 7.673 19.375 7 20 7zM6 11h20v2h-9v-1h-2v1H6zm1 4h18v11h-8V16h-2v10H7z"
            />
          </symbol>
          <symbol
            xmlns="http://www.w3.org/2000/svg"
            id="return"
            viewBox="0 0 32 32"
          >
            <path
              fill="currentColor"
              d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13s13-5.832 13-13h-2c0 6.086-4.914 11-11 11S5 22.086 5 16S9.914 5 16 5c3.875 0 7.262 1.984 9.219 5H20v2h8V4h-2v3.719C23.617 4.844 20.02 3 16 3z"
            />
          </symbol>
          <symbol
            xmlns="http://www.w3.org/2000/svg"
            id="quote"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="m15 17l2-4h-4V6h7v7l-2 4h-3Zm-9 0l2-4H4V6h7v7l-2 4H6Z"
            />
          </symbol>
          <symbol
            xmlns="http://www.w3.org/2000/svg"
            id="nav-icon"
            viewBox="0 0 16 16"
          >
            <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z" />
          </symbol>
          <symbol
            xmlns="http://www.w3.org/2000/svg"
            id="close"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </symbol>
          <symbol
            xmlns="http://www.w3.org/2000/svg"
            id="navbar-icon"
            viewBox="0 0 16 16"
          >
            <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z" />
          </symbol>
        </svg>
        <div className="search-box position-relative overflow-hidden w-100">
          <div className="search-wrap">
            <div className="close-button position-absolute">
              <svg className="close" width="22" height="22">
                <use xlinkHref="#close"></use>
              </svg>
            </div>
            <form
              id="search-form"
              className="text-center pt-3"
              action=""
              method=""
            >
              <input
                type="text"
                className="search-input fs-5 p-4 bg-transparent"
                placeholder="Search..."
              />
              <svg className="search" width="22" height="22">
                <use xlinkHref="#search"></use>
              </svg>
            </form>
          </div>
        </div>

        <header id="header" className="site-header text-black">
          <nav id="header-nav" className="navbar navbar-expand-lg px-3 mb-3">
            <div className="container-fluid">
              <a className="navbar-brand" href="index.html">
                <img
                  src="images/logo.png"
                  className="logo"
                  style={{ width: "200px", height: "100px" }}
                />
              </a>
              <button
                className="navbar-toggler d-flex d-lg-none order-3 p-2"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#bdNavbar"
                aria-controls="bdNavbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <svg className="navbar-icon" width="50" height="50">
                  <use href="#navbar-icon"></use>
                </svg>
              </button>
              <div
                className="offcanvas offcanvas-end"
                tabindex="-1"
                id="bdNavbar"
                aria-labelledby="bdNavbarOffcanvasLabel"
              >
                <div className="offcanvas-header px-4 pb-0">
                  <a className="navbar-brand" href="index.html">
                    <img src="images/main-logo.png" className="logo" />
                  </a>
                  <button
                    type="button"
                    className="btn-close btn-close-black"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    data-bs-target="#bdNavbar"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <ul
                    id="navbar"
                    className="navbar-nav text-uppercase justify-content-end align-items-center flex-grow-1 pe-3"
                  >
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link me-4 active dropdown-toggle"
                        href="#billboard"
                        id="dropdownPages"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Home
                      </a>
                      <ul
                        className="dropdown-menu list-unstyled"
                        aria-labelledby="dropdownPages"
                      >
                        <li>
                          <a
                            href="index.html"
                            className="dropdown-item item-anchor"
                          >
                            Homepage 1
                          </a>
                        </li>
                        <li>
                          <a
                            href="home2.html"
                            className="dropdown-item item-anchor"
                          >
                            Homepage V2{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link me-4" href="#about-us">
                        About Us
                      </a>
                    </li>
                    <li className="nav-item dropdown me-4">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="dropdownPages"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Shop
                      </a>
                      <ul
                        className="dropdown-menu list-unstyled"
                        aria-labelledby="dropdownPages"
                      >
                        <li>
                          <a
                            href="shop-sidebar.html"
                            className="dropdown-item item-anchor"
                          >
                            Shop Sidebar{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="shop-four-column.html"
                            className="dropdown-item item-anchor"
                          >
                            Shop Grid{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown me-4">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="dropdownPages"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Blog
                      </a>
                      <ul
                        className="dropdown-menu list-unstyled"
                        aria-labelledby="dropdownPages"
                      >
                        <li>
                          <a
                            href="blog-classic.html"
                            className="dropdown-item item-anchor"
                          >
                            Blog Classic{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="blog-grid-four-column.html"
                            className="dropdown-item item-anchor"
                          >
                            Blog 4 Column{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="blog-with-sidebar.html"
                            className="dropdown-item item-anchor"
                          >
                            Blog With Sidebar{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="dropdownPages"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Pages
                      </a>
                      <ul
                        className="dropdown-menu list-unstyled"
                        aria-labelledby="dropdownPages"
                      >
                        <li>
                          <a
                            href="about-us.html"
                            className="dropdown-item item-anchor"
                          >
                            About{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="cart.html"
                            className="dropdown-item item-anchor"
                          >
                            Cart{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="checkout.html"
                            className="dropdown-item item-anchor"
                          >
                            Checkout{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="contact.html"
                            className="dropdown-item item-anchor"
                          >
                            Contact{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="error-page.html"
                            className="dropdown-item item-anchor"
                          >
                            Error Page{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="faqs.html"
                            className="dropdown-item item-anchor"
                          >
                            FAQs{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="my-account.html"
                            className="dropdown-item item-anchor"
                          >
                            My Account{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="order-tracking.html"
                            className="dropdown-item item-anchor"
                          >
                            Order Tracking{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="wishlist.html"
                            className="dropdown-item item-anchor"
                          >
                            Wishlist{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="single-post.html"
                            className="dropdown-item item-anchor"
                          >
                            Single Post{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="single-product.html"
                            className="dropdown-item item-anchor"
                          >
                            Single Product{" "}
                            <span className="badge bg-secondary text-dark ms-2">
                              PRO
                            </span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <div className="user-items ps-5">
                        <ul className="d-flex justify-content-end list-unstyled">
                          <li
                            className="search-item pe-3"
                            data-bs-toggle="collapse"
                            data-bs-target="#search-box"
                            aria-controls="search-box"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                          >
                            <svg className="search" width="18" height="18">
                              <use href="#search"></use>
                            </svg>
                          </li>
                          <li className="pe-3">
                            <a href="#">
                              <svg className="user" width="18" height="18">
                                <use href="#user"></use>
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a href="cart.html">
                              <svg className="cart" width="18" height="18">
                                <use href="#cart"></use>
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>

        <section id="billboard" className="overflow-hidden">
          <div className="swiper main-swiper">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="banner-item"
                        style={{
                          backgroundImage: "url(images/banner-image1.jpg)",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right",
                          height: "682px",
                        }}
                      >
                        <div className="banner-content padding-large">
                          <h1 className="display-1 text-uppercase text-dark pb-2">
                            Design By Suite Venture Housing
                          </h1>
                          <p>Custom design by our talented team</p>
                          <a
                            href="shop.html"
                            className="btn btn-medium btn-arrow position-relative mt-5"
                          >
                            <span className="text-uppercase">View More</span>
                            <svg
                              className="arrow-right position-absolute"
                              width="18"
                              height="20"
                            >
                              <use href="#arrow-right"></use>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="banner-item"
                        style={{
                          backgroundImage: "url(images/banner-image1.jpg)",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right",
                          height: "682px",
                        }}
                      >
                        <div className="banner-content padding-large">
                          <h1 className="display-1 text-uppercase text-dark pb-2">
                            Comfortable Sofa Set
                          </h1>
                          <p>
                            Aliquet donec ut arcu risus amet mattis diam
                            gravida. Ac vestibulum quis proin in aliquam et et
                            auctor. Amet urna est arcu euismod egestas morbi
                            nunc lacus. Nec id rutrum mauris commodo habitant
                            amet quisque. Velit ornare pellentesque facilisi in
                            odio nibh.
                          </p>
                          <a
                            href="shop.html"
                            className="btn btn-medium btn-arrow position-relative mt-5"
                          >
                            <span className="text-uppercase">View More</span>
                            <svg
                              className="arrow-right position-absolute"
                              width="18"
                              height="20"
                            >
                              <use href="#arrow-right"></use>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-pagination position-absolute"></div>
        </section>

        {/* <section id="company-services" className="padding-large">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-md-6 pb-3">
                <div className="icon-box d-flex align-items-center">
                  <div className="icon-box-icon pt-3 pe-3 pb-3 ps-3">
                    <svg className="shipping-fast">
                      <use href="#shipping-fast" />
                    </svg>
                  </div>
                  <div className="icon-box-content ps-3">
                    <h3 className="card-title text-uppercase text-dark">
                      Quick delivery
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 pb-3">
                <div className="icon-box d-flex align-items-center">
                  <div className="icon-box-icon pt-3 pe-3 pb-3 ps-3">
                    <svg className="shopping-cart">
                      <use href="#shopping-cart" />
                    </svg>
                  </div>
                  <div className="icon-box-content ps-3">
                    <h3 className="card-title text-uppercase text-dark">
                      Pick up in store
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 pb-3">
                <div className="icon-box d-flex align-items-center">
                  <div className="icon-box-icon pt-3 pe-3 pb-3 ps-3">
                    <svg className="gift">
                      <use href="#gift" />
                    </svg>
                  </div>
                  <div className="icon-box-content ps-3">
                    <h3 className="card-title text-uppercase text-dark">
                      Special Packaging
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 pb-3">
                <div className="icon-box d-flex align-items-center">
                  <div className="icon-box-icon pt-3 pe-3 pb-3 ps-3">
                    <svg className="return">
                      <use href="#return" />
                    </svg>
                  </div>
                  <div className="icon-box-content ps-3">
                    <h3 className="card-title text-uppercase text-dark">
                      Return & refund policy
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section id="about-us">
          <div className="container-fluid">
            <div className="row align-items-center justify-content-between g-5">
              <div className="col-lg-6">
                <div className="image-holder mb-4 jarallax">
                  <img
                    src="images/single-image1.jpg"
                    alt="single"
                    className="img-fluid jarallax-img"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="detail p-5">
                  <div className="display-header">
                    <h2 className="display-2 text-uppercase text-dark pb-2">
                      About Us
                    </h2>
                    <p className="pb-3">
                      Spokane based team consisting of ...{" "}
                    </p>
                    <a
                      href="about-us.html"
                      className="btn btn-medium btn-arrow outline-dark position-relative mt-3"
                    >
                      <span className="text-uppercase">About us</span>
                      <svg
                        className="arrow-right position-absolute"
                        width="18"
                        height="20"
                      >
                        <use href="#arrow-right" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="featured-products"
          className="product-store position-relative padding-large"
        >
          <div className="container-fluid">
            <div className="row">
              <div className="display-header pb-3 d-flex justify-content-between flex-wrap col-md-12">
                <h2 className="display-2 text-dark text-uppercase">
                  Todays Featured Rental
                </h2>
                <a
                  href="shop.html"
                  className="btn btn-medium btn-arrow btn-normal position-relative"
                >
                  <span className="text-uppercase">View More</span>
                  <svg
                    className="arrow-right position-absolute"
                    width="18"
                    height="20"
                  >
                    <use xlinkHref="#arrow-right"></use>
                  </svg>
                </a>
              </div>
            </div>
            <div className="row">
              <div id="featured-swiper" className="product-swiper col-md-12">
                <div className="swiper">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="product-card image-zoom-effect link-effect d-flex flex-wrap">
                        <div className="image-holder">
                          <img
                            src="images/pv3LivingRoom.jpeg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                        </div>
                        <div className="cart-concern">
                          <h3 className="card-title text-uppercase pt-3 text-primary">
                            <a
                              href="single-product.html"
                              className="text-primary"
                            >
                              Cozy and Comfort with Georgeous of Downtown
                              Spokane, Wa
                            </a>
                          </h3>
                          <div className="cart-info">
                            <a
                              className="pseudo-text-effect"
                              href="#"
                              data-after="View Pricing"
                            >
                              <span>Contact us for pricing details</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="product-card image-zoom-effect link-effect d-flex flex-wrap">
                        <div className="image-holder">
                          <img
                            src="images/product-item2.jpg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                        </div>
                        <div className="cart-concern">
                          <h3 className="card-title text-uppercase pt-3 text-primary">
                            <a
                              href="single-product.html"
                              className="text-primary"
                            >
                              Circle Dining Table
                            </a>
                          </h3>
                          <div className="cart-info">
                            <a
                              className="pseudo-text-effect"
                              href="#"
                              data-after="ADD TO CART"
                            >
                              <span>$200</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="product-card image-zoom-effect link-effect d-flex flex-wrap">
                        <div className="image-holder">
                          <img
                            src="images/product-item3.jpg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                        </div>
                        <div className="cart-concern">
                          <h3 className="card-title text-uppercase pt-3 text-primary">
                            <a
                              href="single-product.html"
                              className="text-primary"
                            >
                              Minimal Sofa
                            </a>
                          </h3>
                          <div className="cart-info">
                            <a
                              className="pseudo-text-effect"
                              href="#"
                              data-after="ADD TO CART"
                            >
                              <span>$200</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="product-card image-zoom-effect link-effect d-flex flex-wrap">
                        <div className="image-holder">
                          <img
                            src="images/product-item4.jpg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                        </div>
                        <div className="cart-concern">
                          <h3 className="card-title text-uppercase pt-3 text-primary">
                            <a
                              href="single-product.html"
                              className="text-primary"
                            >
                              Pattern Tea Table
                            </a>
                          </h3>
                          <div className="cart-info">
                            <a
                              className="pseudo-text-effect"
                              href="#"
                              data-after="ADD TO CART"
                            >
                              <span>$200</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="product-card image-zoom-effect link-effect d-flex flex-wrap">
                        <div className="image-holder">
                          <img
                            src="images/product-item5.jpg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                        </div>
                        <div className="cart-concern">
                          <h3 className="card-title text-uppercase pt-3 text-primary">
                            <a
                              href="single-product.html"
                              className="text-primary"
                            >
                              Black Sofa Set
                            </a>
                          </h3>
                          <div className="cart-info">
                            <a
                              className="pseudo-text-effect"
                              href="#"
                              data-after="ADD TO CART"
                            >
                              <span>$200</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="product-card image-zoom-effect link-effect d-flex flex-wrap">
                        <div className="image-holder">
                          <img
                            src="images/product-item6.jpg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                        </div>
                        <div className="cart-concern">
                          <h3 className="card-title text-uppercase pt-3 text-primary">
                            <a
                              href="single-product.html"
                              className="text-primary"
                            >
                              Minimal Sofa
                            </a>
                          </h3>
                          <div className="cart-info">
                            <a
                              className="pseudo-text-effect"
                              href="#"
                              data-after="ADD TO CART"
                            >
                              <span>$200</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-pagination text-center mt-5"></div>
              </div>
            </div>
          </div>
        </section>
        <section id="testimonials" className="position-relative">
          <div className="container">
            <div className="row">
              <div className="review-content position-relative">
                <div className="swiper-icon swiper-arrow swiper-arrow-prev position-absolute d-flex align-items-center justify-content-center">
                  <svg className="icon-arrow" width="25" height="25">
                    <use xlinkHref="#arrow-left" />
                  </svg>
                </div>
                <div className="swiper testimonial-swiper">
                  <div className="quotation text-center">
                    <svg className="quote">
                      <use xlinkHref="#quote" />
                    </svg>
                  </div>
                  <div className="swiper-wrapper">
                    <div className="swiper-slide text-center d-flex justify-content-center">
                      <div className="review-item col-md-10">
                        <i className="icon icon-review"></i>
                        <blockquote className="fs-4">
                          Come check out our awesome places.
                        </blockquote>
                        <div className="author-detail">
                          <div className="name text-primary text-uppercase pt-2">
                            Suite Venture Housing Team
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide text-center d-flex justify-content-center">
                      <div className="review-item col-md-10">
                        <i className="icon icon-review"></i>
                        <blockquote className="fs-4">
                          “A blog is a digital publication that can complement a
                          website or exist independently. A blog may include
                          articles, short posts, listicles, infographics,
                          videos, and other digital content.”
                        </blockquote>
                        <div className="author-detail">
                          <div className="name text-primary text-uppercase pt-2">
                            Jennie Rose
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-icon swiper-arrow swiper-arrow-next position-absolute d-flex align-items-center justify-content-center">
                  <svg className="icon-arrow" width="25" height="25">
                    <use xlinkHref="#arrow-right" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-pagination text-center position-absolute"></div>
        </section>

        <section id="collections" className="position-relative padding-large">
          <div className="container-fluid">
            <div className="row">
              <div className="swiper collection-swiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide overflow-hidden">
                    <div className="product-card">
                      <div className="card-detail d-flex justify-content-between align-items-baseline pt-3">
                        <h3 className="card-title text-uppercase">
                          <a href="shop.html">Living Room</a>
                        </h3>
                      </div>
                      <div className="image-overlay position-relative">
                        <div className="product-image">
                          <img
                            src="images/pv1LivingRoom.jpeg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                          <div className="text-box box-slide position-absolute">
                            <div className="text-content p-5 bg-light">
                              <h3>Custom Designed By Our Team</h3>
                              <p>We do everything in house</p>
                              <ul>
                                <li>We aim to accommodate our guests</li>
                                <li>We take pride in our work</li>
                              </ul>
                              <a href="#" className="btn btn-normal mt-3">
                                Learn More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide overflow-hidden">
                    <div className="product-card">
                      <div className="card-detail d-flex justify-content-between align-items-baseline pt-3">
                        <h3 className="card-title text-uppercase">
                          <a href="shop.html">Bed Rooms</a>
                        </h3>
                      </div>
                      <div className="image-overlay position-relative">
                        <div className="product-image">
                          <img
                            src="images/product-item6.jpg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                          <div className="text-box box-slide position-absolute">
                            <div className="text-content p-5 bg-light">
                              <h3>About Room</h3>
                              <p>
                                Assumenda temporibus quidem ipsam. fuga corporis
                                iusto similique voluptates sint quibusdam.
                              </p>
                              <ul>
                                <li>Various Types of Bedroom</li>
                                <li>Different Size of Bed</li>
                                <li>Comfortable and Clean Room</li>
                              </ul>
                              <a href="#" className="btn btn-normal mt-3">
                                Learn More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide overflow-hidden">
                    <div className="product-card">
                      <div className="card-detail d-flex justify-content-between align-items-baseline pt-3">
                        <h3 className="card-title text-uppercase">
                          <a href="shop.html">Kitchens</a>
                        </h3>
                      </div>
                      <div className="image-overlay position-relative">
                        <div className="product-image">
                          <img
                            src="images/product-item7.jpg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                          <div className="text-box box-slide position-absolute">
                            <div className="text-content p-5 bg-light">
                              <h3>About Kitchen</h3>
                              <p>
                                Assumenda temporibus quidem ipsam. fuga corporis
                                iusto similique voluptates sint quibusdam.
                              </p>
                              <ul>
                                <li>Various Types of Bedroom</li>
                                <li>Different Size of Bed</li>
                                <li>Comfortable and Clean Room</li>
                              </ul>
                              <a href="#" className="btn btn-normal mt-3">
                                Learn More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide overflow-hidden">
                    <div className="product-card">
                      <div className="card-detail d-flex justify-content-between align-items-baseline pt-3">
                        <h3 className="card-title text-uppercase">
                          <a href="shop.html">Guest Rooms</a>
                        </h3>
                      </div>
                      <div className="image-overlay position-relative">
                        <div className="product-image">
                          <img
                            src="images/product-item8.jpg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                          <div className="text-box box-slide position-absolute">
                            <div className="text-content p-5 bg-light">
                              <h3>About Kitchen</h3>
                              <p>
                                Assumenda temporibus quidem ipsam. fuga corporis
                                iusto similique voluptates sint quibusdam.
                              </p>
                              <ul>
                                <li>Various Types of Bedroom</li>
                                <li>Different Size of Bed</li>
                                <li>Comfortable and Clean Room</li>
                              </ul>
                              <a href="#" className="btn btn-normal mt-3">
                                Learn More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-pagination position-absolute text-center"></div>
        </section>
        <section className="subscribe">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8">
                <div className="subscribe-content padding-large">
                  <div className="subscribe-header">
                    <h2 className="display-4">
                      Get offers & discounts by subscribing us
                    </h2>
                  </div>
                  <form id="form">
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter Your Email Addresss"
                      className="w-100 bg-light border-0 ps-5 fst-italic"
                    />
                    <button
                      type="submit"
                      className="btn btn-full btn-black text-uppercase"
                    >
                      Subscribe Now
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="trending-products" className="product-store padding-large">
          <div className="container-fluid">
            <div className="row overflow-hidden">
              <div className="display-header pb-3 d-flex justify-content-between col-md-12">
                <h2 className="display-2 text-dark text-uppercase">
                  Trending rentals
                </h2>
                <a
                  href="shop.html"
                  className="btn btn-medium btn-arrow btn-normal position-relative"
                >
                  <span className="text-uppercase">View More</span>
                  <svg
                    className="arrow-right position-absolute"
                    width="18"
                    height="20"
                  >
                    <use xlinkHref="#arrow-right"></use>
                  </svg>
                </a>
              </div>
              <div id="trending-swiper" className="product-swiper col-md-12">
                <div className="swiper">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="product-card image-zoom-effect link-effect d-flex flex-wrap">
                        <div className="image-holder">
                          <img
                            src="images/product-item1.jpg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                        </div>
                        <div className="cart-concern">
                          <h3 className="card-title text-uppercase pt-3 text-primary">
                            <a href="single-product.html">Black Sofa Set</a>
                          </h3>
                          <div className="cart-info">
                            <a
                              className="pseudo-text-effect"
                              href="#"
                              data-after="Want to view more like this?"
                            >
                              <span>Contact our team for pricing</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="product-card image-zoom-effect link-effect d-flex flex-wrap">
                        <div className="image-holder">
                          <img
                            src="images/product-item2.jpg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                        </div>
                        <div className="cart-concern">
                          <h3 className="card-title text-uppercase pt-3 text-primary">
                            <a href="single-product.html">
                              Circle Dining Table
                            </a>
                          </h3>
                          <div className="cart-info">
                            <a
                              className="pseudo-text-effect"
                              href="#"
                              data-after="ADD TO CART"
                            >
                              <span>$200</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="product-card image-zoom-effect link-effect d-flex flex-wrap">
                        <div className="image-holder">
                          <img
                            src="images/product-item3.jpg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                        </div>
                        <div className="cart-concern">
                          <h3 className="card-title text-uppercase pt-3 text-primary">
                            <a href="single-product.html">Minimal Sofa</a>
                          </h3>
                          <div className="cart-info">
                            <a
                              className="pseudo-text-effect"
                              href="#"
                              data-after="ADD TO CART"
                            >
                              <span>$200</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="product-card image-zoom-effect link-effect d-flex flex-wrap">
                        <div className="image-holder">
                          <img
                            src="images/product-item4.jpg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                        </div>
                        <div className="cart-concern">
                          <h3 className="card-title text-uppercase pt-3 text-primary">
                            <a href="single-product.html">Pattern Tea Table</a>
                          </h3>
                          <div className="cart-info">
                            <a
                              className="pseudo-text-effect"
                              href="#"
                              data-after="ADD TO CART"
                            >
                              <span>$200</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="product-card image-zoom-effect link-effect d-flex flex-wrap">
                        <div className="image-holder">
                          <img
                            src="images/product-item5.jpg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                        </div>
                        <div className="cart-concern">
                          <h3 className="card-title text-uppercase pt-3 text-primary">
                            <a href="single-product.html">Black Sofa Set</a>
                          </h3>
                          <div className="cart-info">
                            <a
                              className="pseudo-text-effect"
                              href="#"
                              data-after="ADD TO CART"
                            >
                              <span>$200</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="product-card image-zoom-effect link-effect d-flex flex-wrap">
                        <div className="image-holder">
                          <img
                            src="images/product-item6.jpg"
                            alt="product-item"
                            className="product-image img-fluid"
                          />
                        </div>
                        <div className="cart-concern">
                          <h3 className="card-title text-uppercase pt-3 text-primary">
                            <a href="single-product.html">Minimal Sofa</a>
                          </h3>
                          <div className="cart-info">
                            <a
                              className="pseudo-text-effect"
                              href="#"
                              data-after="ADD TO CART"
                            >
                              <span>$200</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-pagination text-center mt-5"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="latest-blog">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="display-header d-flex flex-wrap justify-content-between pb-3">
                  <h2 className="display-2 text-dark text-uppercase">
                    Check Out Our Social Media
                  </h2>
                  <a
                    href="blog.html"
                    className="btn btn-medium btn-arrow btn-normal position-relative"
                  >
                    <span className="text-uppercase">View</span>
                    <svg
                      className="arrow-right position-absolute"
                      width="18"
                      height="20"
                    >
                      <use xlinkHref="#arrow-right"></use>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div className="row g-3 post-grid">
              <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
                <div className="card-item">
                  <div className="card border-0 bg-transparent">
                    <div className="card-image">
                      <img
                        src="images/post-item1.jpg"
                        alt=""
                        className="post-image img-fluid"
                      />
                    </div>
                  </div>
                  <div className="card-body p-0 mt-4">
                    <h3 className="card-title text-uppercase">
                      <a href="single-post.html">
                        Best looking interior things for bedrooms
                      </a>
                    </h3>
                    <p>Custom designs that vary depending on the layout</p>
                    <a
                      href="single-post.html"
                      className="btn btn-normal text-uppercase p-0"
                    >
                      <em>Read More</em>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
                <div className="card-item">
                  <div className="card border-0">
                    <div className="card-image">
                      <img
                        src="images/post-item2.jpg"
                        alt=""
                        className="post-image img-fluid"
                      />
                    </div>
                  </div>
                  <div className="card-body p-0 mt-4">
                    <h3 className="card-title text-uppercase">
                      <a href="single-post.html">Trending rentals</a>
                    </h3>
                    <p>Check us out</p>
                    <a
                      href="single-post.html"
                      className="btn btn-normal text-uppercase p-0"
                    >
                      <em>Read More</em>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
                <div className="card-item">
                  <div className="card border-0">
                    <div className="card-image">
                      <img
                        src="images/post-item3.jpg"
                        alt=""
                        className="post-image img-fluid"
                      />
                    </div>
                  </div>
                  <div className="card-body p-0 mt-4">
                    <h3 className="card-title text-uppercase">
                      <a href="single-post.html">
                        Why our rentals look awesome
                      </a>
                    </h3>
                    <p>Custom Designs</p>
                    <a
                      href="single-post.html"
                      className="btn btn-normal text-uppercase p-0"
                    >
                      <em>Read More</em>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section
          id="brand-collection"
          className="padding-small border-top border-bottom overflow-hidden margin-large mb-0"
        >
          <div className="container">
            <div className="d-flex flex-wrap justify-content-between align-items-center gap-3">
              <a href="#">
                <img src="images/brand-logo-1.svg" alt="brand" />
              </a>
              <a href="#">
                <img src="images/brand-logo-2.svg" alt="brand" />
              </a>
              <a href="#">
                <img src="images/brand-logo-3.svg" alt="brand" />
              </a>
              <a href="#">
                <img src="images/brand-logo-4.svg" alt="brand" />
              </a>
              <a href="#">
                <img src="images/brand-logo-5.svg" alt="brand" />
              </a>
            </div>
          </div>
        </section> */}
        <footer id="footer" className="overflow-hidden padding-large">
          <div className="container-fluid">
            <div className="row">
              <div className="row d-flex flex-wrap justify-content-between">
                <div className="col-lg-3 col-sm-6 pb-3 pe-4">
                  <div className="footer-menu">
                    <img
                      src="images/logo.png"
                      className="logo"
                      style={{ width: "200px", height: "100px" }}
                    />
                    <p>Meet our team</p>
                  </div>
                  <div className="copyright">
                    <p>
                      © Copyright 2023. Design by{" "}
                      <a href="https://templatesjungle.com/">
                        Suite Venture Housing
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-6 pb-3">
                  <div className="footer-menu text-uppercase">
                    <h5 className="widget-title pb-2">Quick Links</h5>
                    <ul className="menu-list list-unstyled text-uppercase">
                      <li className="menu-item pb-2">
                        <a href="#billboard">Home</a>
                      </li>
                      <li className="menu-item pb-2">
                        <a href="#about-us">About</a>
                      </li>
                      <li className="menu-item pb-2">
                        <a href="#company-services">Services</a>
                      </li>
                      <li className="menu-item pb-2">
                        <a href="#latest-blog">Blogs</a>
                      </li>
                      <li className="menu-item pb-2">
                        <a href="#contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-6 pb-3">
                  <div className="footer-menu text-uppercase">
                    <h5 className="widget-title pb-2">Social</h5>
                    <div className="social-links">
                      <ul className="list-unstyled">
                        <li className="pb-2">
                          <a href="#">Facebook</a>
                        </li>
                        <li className="pb-2">
                          <a href="#">Twitter</a>
                        </li>
                        <li className="pb-2">
                          <a href="#">Pinterest</a>
                        </li>
                        <li className="pb-2">
                          <a href="#">Instagram</a>
                        </li>
                        <li>
                          <a href="#">Youtube</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="footer-menu contact-item">
                    <h5 className="widget-title text-uppercase pb-2">
                      Contact Us
                    </h5>
                    <p>
                      <a href="">1-800-123-4567</a>
                    </p>
                    <p>
                      <a href="mailto:">info@suiteventurehousing.com</a>
                    </p>
                    <p>Spokane WA, USA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <script src="/js/jquery-1.11.0.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/swiper/swiper-bundle.min.js"></script>
        <script
          type="text/javascript"
          src="/js/bootstrap.bundle.min.js"
        ></script>
        <script type="text/javascript" src="/js/plugins.js"></script>
        <script type="text/javascript" src="/js/script.js"></script>
      </body>
    </>
  );
};

export default Home;
