import React, { useState, useEffect } from "react";
import { Modal, Button, Typography, Box } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment, { Moment } from "moment";
import AxiosUtil from "../../Utils/AxiosUtil";
import UnitAvailability from "../../Interfaces/Admin/UnitAvailability";
import Availability from "../../Interfaces/Admin/Availability";
import Unit from "../../Interfaces/Admin/Unit";
import Spinner from "../Spinner";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer, Event } from "react-big-calendar";

const localizer = momentLocalizer(moment);

interface CustomEvent extends Event {
  unitId: number;
}

const initialUnits: Unit[] = [
  { unitId: 1, unitName: "PV1", availability: [] },
  { unitId: 2, unitName: "PV2", availability: [] },
  { unitId: 3, unitName: "PV3", availability: [] },
  { unitId: 4, unitName: "GV", availability: [] },
  { unitId: 5, unitName: "SHH", availability: [] },
];

const eventStyleGetter = (event: Event) => {
  let backgroundColor = "#3174ad"; // Default color
  if (event.title === "PV1") {
    backgroundColor = "#FF5733";
  }
  if (event.title === "PV2") {
    backgroundColor = "#33FF57";
  }
  if (event.title === "PV3") {
    backgroundColor = "#3357FF";
  }
  if (event.title === "GV") {
    backgroundColor = "#F1C40F";
  }
  if (event.title === "SHH") {
    backgroundColor = "#9B59B6";
  }

  const style = {
    backgroundColor,
    borderRadius: "5px",
    opacity: 0.8,
    color: "white",
    border: "0px",
    display: "block",
  };
  return {
    style: style,
  };
};

const AvailabilityManager: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState<Unit[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentUnitId, setCurrentUnitId] = useState<number | null>(null);
  const [currentAvailability, setCurrentAvailability] =
    useState<UnitAvailability>();
  const [newAvailability, setNewAvailability] = useState<Availability>({
    startDate: moment(),
    endDate: moment(),
  });
  const [events, setEvents] = useState<CustomEvent[]>([]);

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const url = `https://us-central1-suite-venture-housing.cloudfunctions.net/getAvailability?id=qaddhlP6ipvpj7vYdZki`;
        const availability = await AxiosUtil.get<UnitAvailability, String>(
          url,
          ""
        );
        availability.id = "qaddhlP6ipvpj7vYdZki";
        let currentUnits = availability.units;
        if (!currentUnits || currentUnits.length === 0) {
          currentUnits = initialUnits;
        }
        const currentEvents = buildEventsFromAvailability(availability);
        setEvents(currentEvents);
        setUnits(currentUnits);
        setCurrentAvailability(availability);
      } catch (error) {
        console.error("Error fetching availability:", error);
      }
    };

    fetchAvailability();
  }, [events]);

  const buildEventsFromAvailability = (
    unitAvailability: UnitAvailability
  ): CustomEvent[] => {
    const events: CustomEvent[] = [];

    // Loop through each unit
    unitAvailability.units.forEach((unit) => {
      // Loop through each availability period in the unit
      unit.availability.forEach((availability) => {
        events.push({
          title: `${unit.unitName}`,
          start: moment(availability.startDate).toDate(), // Convert Moment to Date object
          end: moment(availability.endDate).toDate(), // Convert Moment to Date object
          unitId: unit.unitId,
        });
      });
    });

    return events;
  };

  // Opens the modal for a specific unit
  const handleOpenModal = (unitId: number) => {
    setCurrentUnitId(unitId);
    setIsModalOpen(true);
  };

  // Closes the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentUnitId(null);
  };

  const handleAddAvailability = async () => {
    if (currentUnitId !== null) {
      // Calculate the updated units with the new availability for the current unit
      const updatedUnits = units?.map((unit) =>
        unit.unitId === currentUnitId
          ? { ...unit, availability: [...unit.availability, newAvailability] }
          : unit
      );

      // Set the updated units to the state
      setUnits(updatedUnits);

      // After updating the state, persist the changes to the backend
      try {
        setLoading(true);
        const url = `https://us-central1-suite-venture-housing.cloudfunctions.net/updateAvailability`;

        // Make sure to use the updatedUnits array to update currentAvailability
        if (!currentAvailability) {
          setLoading(false);
          return;
        }
        currentAvailability.units = updatedUnits ?? []; // Use updatedUnits instead of units

        // Send the updated availability to the backend
        await AxiosUtil.post<String, UnitAvailability>(
          url,
          currentAvailability
        );

        // Rebuild the events and close the modal
        const updatedEvents = buildEventsFromAvailability(currentAvailability);
        setEvents(updatedEvents);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        alert("Failed to save availability");
      }
    }
  };

  const handleDeleteAvailability = async (indexToDelete: number) => {
    if (currentUnitId !== null) {
      // Calculate the updated units by filtering out the deleted availability
      const updatedUnits = units?.map((unit) =>
        unit.unitId === currentUnitId
          ? {
              ...unit,
              availability: unit.availability.filter(
                (_a, index) => index !== indexToDelete
              ),
            }
          : unit
      );

      // Set the updated units to the state
      setUnits(updatedUnits);

      // After updating the state, persist the changes to the backend
      try {
        setLoading(true);
        const url = `https://us-central1-suite-venture-housing.cloudfunctions.net/updateAvailability`;

        // Make sure to use the updatedUnits array to update currentAvailability
        if (!currentAvailability) {
          setLoading(false);
          return;
        }
        currentAvailability.units = updatedUnits ?? []; // Use updatedUnits instead of units

        // Send the updated availability to the backend
        await AxiosUtil.post<String, UnitAvailability>(
          url,
          currentAvailability
        );

        // Rebuild the events and close the modal if needed
        const updatedEvents = buildEventsFromAvailability(currentAvailability);
        setEvents(updatedEvents);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        alert("Failed to delete availability");
      }
    }
  };

  const handleDateChange = (newDate: [Moment | null, Moment | null]) => {
    if (newDate[0] && newDate[1]) {
      setNewAvailability({ startDate: newDate[0], endDate: newDate[1] });
    }
  };

  const currentUnit =
    currentUnitId !== null
      ? units?.find((unit) => unit.unitId === currentUnitId)
      : null;

  return (
    <>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        eventPropGetter={eventStyleGetter}
      />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div>
          {loading && <Spinner></Spinner>}
          {units?.map((unit) => (
            <Box key={unit.unitId} mb={2}>
              <Button
                variant="contained"
                onClick={() => handleOpenModal(unit.unitId)}
              >
                {unit.unitName}
              </Button>
            </Box>
          ))}

          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Box
              sx={{
                p: 4,
                backgroundColor: "white",
                margin: "auto",
                width: 400,
                mt: "20vh",
                borderRadius: 2,
              }}
            >
              <Typography variant="h6" mb={2}>
                Manage Availability for {currentUnit?.unitName}
              </Typography>

              {currentUnit?.availability.map((a: Availability, index) => (
                <Box key={index} display="flex" justifyContent="space-between">
                  <Typography>
                    {`Start: ${
                      moment.isMoment(a.startDate)
                        ? a.startDate.format("YYYY-MM-DD HH:mm")
                        : moment(a.startDate).format("YYYY-MM-DD HH:mm")
                    }, End: ${
                      moment.isMoment(a.endDate)
                        ? a.endDate.format("YYYY-MM-DD HH:mm")
                        : moment(a.endDate).format("YYYY-MM-DD HH:mm")
                    }`}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteAvailability(index)}
                  >
                    Delete
                  </Button>
                </Box>
              ))}

              <Box mt={2}>
                <DateTimePicker
                  label="Start Date"
                  value={newAvailability.startDate}
                  onChange={(date) =>
                    handleDateChange([date, newAvailability.endDate])
                  }
                />
                <DateTimePicker
                  label="End Date"
                  value={newAvailability.endDate}
                  onChange={(date) =>
                    handleDateChange([newAvailability.startDate, date])
                  }
                />
              </Box>

              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddAvailability}
                >
                  Add Availability
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
      </LocalizationProvider>
    </>
  );
};

export default AvailabilityManager;
